import { Stack } from "@mui/system";
import { AppDrawer } from "../../components/drawer";
import AppHeader from "../../components/header";
import { MemoryRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  DefaultCalendar,
  MessageHistory,
  PersonalCalendar,
  StudiCalendar,
  UserCalendar,
} from "@bludata/calendar";
import AppFooter from "../../components/footer";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store";
import { Box } from "@mui/material";
import {
  DashboardAgenda,
  Generali,
  Layout,
  NewPointOfSales,
  NewUsers,
  NuovaRota,
  Services,
  Studi,
} from "@bludata/configurazione-components";
import { Fragment, memo, useEffect } from "react";
import axios from "axios";
import {
  requestInterceptor,
  requestErrorInterceptor,
  responseErrorInterceptor,
  responseInterceptor,
  setRequestInterceptorId,
  setResponseInterceptorId,
} from "../../lib/interceptors";
import { setIsBluBookingEnabled } from "../../store/root";
import jwt_decode from "jwt-decode";
import { useQueryClient } from "@tanstack/react-query";
import { SocketManager } from "@bludata/components";
import { getCookie } from "../../lib/cookie";
import { refreshToken } from "../../api/refresh-token";

export const Home = memo(() => {
  const {
    darkMode,
    pointOfSaleId,
    accessToken,
    socketChannel,
    companyColor,
    software,
    isAdmin,
    isManualAppointmentBlock,
    isSingleCalendarManagement,
    visibilityUserEnum,
  } = useSelector((state: RootState) => state.root);
  console.log("visibilityUserEnum", visibilityUserEnum);

  console.log("isManualAppointmentBlock", isManualAppointmentBlock);

  const queryClient = useQueryClient();

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  if (accessToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    try {
      const token = jwt_decode(accessToken);
      store.dispatch(
        setIsBluBookingEnabled(
          ((token as any)?.IsBluBookingActive ?? "false") === "true"
        )
      );
    } catch {}
  } else {
    axios.defaults.headers.common["Authorization"] = "";
  }

  const requestInterceptorId = axios.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor
  );
  setRequestInterceptorId(requestInterceptorId);

  const responseInterceptorId = axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );
  setResponseInterceptorId(responseInterceptorId);

  useEffect(() => {
    const socketManager = new SocketManager({
      url: "wss://notify.bludata.com:443/sgc/auth/url/F03B6B4B0301496498743FCBB48A059B/198F0D18297F4CA18EF5B1E3EA16E3DE",
    });

    if (socketChannel) {
      socketManager.subscribeSocketChannel(socketChannel);

      window.addEventListener("socketEvent", (e: any) => {
        if (
          e?.detail &&
          e?.detail?.event?.channel.indexOf("blubooking:") >= 0
        ) {
          queryClient.invalidateQueries(["notify"], {
            type: "active",
          });
          queryClient.removeQueries(["notify"], {
            type: "inactive",
          });
        }
      });
    }

    return () => {
      if (socketChannel) socketManager.unsubscribeSocketChannel(socketChannel);
      socketManager.closeSocket();
    };
  }, [queryClient, socketChannel]);

  console.log("SOFTWARE", software);
  const softwareType = software === 2 ? "bb" : "f10";

  //!solo per il layout viene gestita la tipolgia di software diversamente dagli altri rami (sarebbe la versione corretta)
  const softwareTypeLayout =
    software === 0 ? "f10" : software === 2 ? "bb" : "fw";

  return (
    <Stack flex="1" flexDirection="column">
      <MemoryRouter initialEntries={["/"]}>
        <AppHeader />
        <Stack flex="1" flexDirection="row">
          <AppDrawer />
          <Box sx={{ flex: 1, position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate
                      to={`/${
                        localStorage.getItem("BluBookingCalendar") ?? "calendar"
                      }`}
                    />
                  }
                />
                <Route
                  path="calendar"
                  element={
                    <DefaultCalendar
                      darkMode={darkMode}
                      pointOfSale={pointOfSaleId}
                      companyColor={companyColor}
                      isManualAppointmentBlock={isManualAppointmentBlock}
                      visibilityUserEnum={visibilityUserEnum}
                    />
                  }
                />

                <Route
                  path="personalCalendar"
                  element={
                    <PersonalCalendar
                      darkMode={darkMode}
                      pointOfSale={pointOfSaleId}
                      companyColor={companyColor}
                      isManualAppointmentBlock={isManualAppointmentBlock}
                      visibilityUserEnum={visibilityUserEnum}
                    />
                  }
                />
                <Route
                  path="userCalendar"
                  element={
                    <UserCalendar
                      darkMode={darkMode}
                      pointOfSale={pointOfSaleId}
                      companyColor={companyColor}
                      isManualAppointmentBlock={isManualAppointmentBlock}
                      visibilityUserEnum={visibilityUserEnum}
                    />
                  }
                />
                <Route
                  path="studioCalendar"
                  element={
                    <StudiCalendar
                      darkMode={darkMode}
                      pointOfSale={pointOfSaleId}
                      companyColor={companyColor}
                      isManualAppointmentBlock={isManualAppointmentBlock}
                      visibilityUserEnum={visibilityUserEnum}
                    />
                  }
                />
                <Route
                  path="messageHistory"
                  element={<MessageHistory root="Agenda / " />}
                />
                <Route path="/config" element={<Navigate to="/services" />} />

                <Route
                  path="services"
                  element={<Services root="Configurazione / " />}
                />
                <Route
                  path="studi"
                  element={<Studi root="Configurazione / " />}
                />

                <Route
                  path="punti-vendita"
                  element={
                    <Fragment key="point-of-sales">
                      <NewPointOfSales
                        software={softwareType}
                        isConsole
                        root="Configurazione / "
                        isBluBookingActive
                        isSingleCalendarManagement={isSingleCalendarManagement}
                      />
                    </Fragment>
                  }
                />
                <Route
                  path="users"
                  element={
                    <Fragment key="users">
                      <NewUsers
                        software={softwareType}
                        isConsole
                        root="Configurazione / "
                        isBluBookingActive
                        isSingleCalendarManagement={isSingleCalendarManagement}
                      />
                    </Fragment>
                  }
                />
                <Route
                  path="shop-signs"
                  element={
                    <Fragment key="shop-sign">
                      <div
                        style={{
                          width: "100%",
                          overflowY: "scroll",
                        }}
                      >
                        <Layout
                          software={softwareTypeLayout}
                          isConsole={true}
                          root="Configurazione / "
                        />
                      </div>
                    </Fragment>
                  }
                />
                <Route
                  path="turni-degli-appuntamenti"
                  element={
                    <Fragment key="rota">
                      <NuovaRota
                        root="Agenda / "
                        pointOfSaleId={pointOfSaleId}
                        isAdmin={isAdmin}
                        isConsole={true}
                        isSingleCalendarManagement={isSingleCalendarManagement}
                      />
                    </Fragment>
                  }
                />

                <Route
                  path="generali"
                  element={
                    <Fragment key="generali">
                      <Generali
                        software="bb"
                        root="Configurazione / "
                        refreshToken={() => {
                          const rf = getCookie("BluBookingRefreshToken");
                          if (rf !== undefined && rf !== "") refreshToken(rf);
                        }}
                      />
                    </Fragment>
                  }
                />

                <Route
                  path="analisi-degli-appuntamenti"
                  element={
                    <Fragment key="analisi-degli-appuntamenti">
                      <DashboardAgenda software="bb" root="Agenda / " />
                    </Fragment>
                  }
                />
              </Routes>
            </Box>
          </Box>
        </Stack>
        <AppFooter />
      </MemoryRouter>
    </Stack>
  );
});
